import React, { useEffect, useState, useRef, useContext } from "react";
import { styled } from "@mui/system";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core";
import "cropperjs/dist/cropper.min.css";
import "./newPublication.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Resizer from "react-image-file-resizer";
import app from "../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { AuthContext } from "../Auth";
import {
  doc,
  updateDoc,
  setDoc,
  getFirestore,
  addDoc,
  collection,
} from "firebase/firestore";

import JSZip from "jszip";
import { saveAs } from "file-saver";

const useStyles = makeStyles({
  displayImgContainer: (props) => ({
    width: "600px",
    height: "600px",
    backgroundSize: "contain",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#646464",
    margin: "0 0 20px 0",
    backgroundImage: props.backgroundImage,
  }),
  hideDisplayImgContainer: {
    display: "none",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const TitleText = styled("div")({
  fontSize: "15px",
  fontWeight: "600",
  margin: "35px 0 10px",
});

const ContentUploadContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  backgroundColor: "#4f4f4f",
  padding: "40px 25px",
  width: "100%",
  borderRadius: "5px",
});

const TextFieldContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const DetailsPreviewContainer = styled("div")({
  width: "200px",
  height: "50px",
  fontSize: "14px",
  margin: "2px 0",
});

const DisplayLeftContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const TextAreaContainer = styled(TextField)({
  width: "100%",
});

const LicenseText = styled("div")({
  fontSize: "14px",
  margin: "0 0 10px 0",
  color: "#393939",
});

const PriceLicenseContainer = styled("div")({
  display: "flex",
  alignItems: "flex-end",
  margin: "0 0 10px 0",
});

const LicenseTitle = styled("div")({
  fontSize: "14px",
  fontWeight: "600",
});

const GuidelineContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  fontSize: "14px",
  backgroundColor: "#fff",
  padding: "25px 25px",
  width: "100%",
  borderRadius: "5px",
  margin: "0 0 40px 0",
});

const SectionDescription = styled("div")({
  fontSize: "14px",
});

const GuidelineText = styled("div")({
  margin: "0 0 10px 0",
});

const ProfilePic = styled("img")({
  display: "flex",
  justifyContent: "center",
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  filter: "drop-shadow(-1px 1px 4px gray)",
});

export default function ({ userDBInfo }) {
  // Info for uploading
  const [picUpload, setPicUpload] = useState([]);
  const [sourceUpload, setSourceUpload] = useState([]);
  const [thumbnailUpload, setThumbnailUpload] = useState([]);
  const [contentDetail, setContentDetail] = useState({
    caption: "",
    description: "",
    standardLicense: null,
    premLicense: null,
  });

  const [files, setFiles] = useState([]);
  const [img, setImg] = useState(null); // Resized for thumbnail and upload
  const [nonResizedImg, setNonResizedImg] = useState(null);
  const [showDisplayPic, setShowDisplayPic] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [cropper, setCropper] = useState();
  // const [cropData, setCropData] = useState("#");
  const [showThumbnailContainer, setShowThumbnailContainer] = useState(false);
  const [contentInfo, setContentInfo] = useState({
    title: "",
    description: "",
  });
  const [showSourceText, setShowSourceText] = useState(false);
  const jszip = new JSZip();
  const { currentUser } = useContext(AuthContext);
  const [resizedFile, setResizedFile] = useState();

  //   Passing state to CSS to render backgroundImg
  const props = {
    backgroundImage: "url(" + nonResizedImg + ")",
  };

  //   Global Firebase info
  const db = getFirestore(app);

  const classes = useStyles(props);

  useEffect(() => {
    console.log("newPublications.js", userDBInfo);
  }, [picUpload, contentDetail, nonResizedImg]);

  const RenderLicenseComponent = () => {
    return (
      <FormControl sx={{ width: "60%" }} className="formContainer">
        <TitleText>License Price</TitleText>
        <RadioGroup
          sx={{
            backgroundColor: "#fff",
            width: "100%",
            padding: "25px",
            borderRadius: "5px",
          }}
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
        >
          <PriceLicenseContainer>
            <FormControl>
              <Input
                sx={{ width: "50px" }}
                id="standardLicenseAmt"
                value={contentDetail.standardLicense}
                onChange={(e) => {
                  setContentDetail({
                    ...contentDetail,
                    standardLicense: e.target.value,
                  });
                }}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </FormControl>
            <LicenseTitle>Standard Illustration License </LicenseTitle>
          </PriceLicenseContainer>
          <LicenseText>
            Standard Illustration License for most creative and usage needs,
            including unlimited web distribution; prints up to 500,000 copies;
            video production up to 500,000 impressions; and OOH advertisements
            up to 500,000 impressions. Restriction apply for use in
            merchandising, and commerical spaces.
          </LicenseText>
          <PriceLicenseContainer>
            <FormControl>
              <Input
                sx={{ width: "50px" }}
                id="premLicenseAmt"
                value={contentDetail.premLicense}
                onChange={(e) => {
                  setContentDetail({
                    ...contentDetail,
                    premLicense: e.target.value,
                  });
                }}
                startAdornment={
                  <InputAdornment position="start">$</InputAdornment>
                }
              />
            </FormControl>
            <LicenseTitle>Premium Illustration License </LicenseTitle>
          </PriceLicenseContainer>
          <LicenseText>
            Premium Illustration License inclues all the usage rights of our
            Standard Illustration License. It also enables unlimited web
            distribution, print copies, packaging copies, OOH advertising
            impressions, merchandising, video production, and decoration in
            commercial spaces.
          </LicenseText>
        </RadioGroup>
      </FormControl>
    );
  };

  const RenderThumbnailComponent = () => {
    return (
      <Box className="contentDescriptionContainer" sx={{ width: "100%" }}>
        <Box
          className="thumbnailComp"
          sx={{
            width: "100%",
            backgroundColor: "#fff",
            padding: "20px 25px",
            margin: "35px 0 0 0",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center]",
            flexDirection: "column",
          }}
        >
          <Box
            className="leftAndPreviewContainer"
            sx={{
              display: "flex",
              width: "100%",
            }}
          >
            <DisplayLeftContainer className="displayLeftContainer">
              <TextFieldContainer className="textFieldContainer">
                <TextField
                  //   id="standard-basic"
                  key="title"
                  label="Caption"
                  variant="standard"
                  focused
                  value={contentDetail.caption}
                  inputProps={{ maxLength: 55 }}
                  onChange={(e) =>
                    setContentDetail({
                      ...contentDetail,
                      caption: e.target.value,
                    })
                  }
                />
                <TextField
                  id="standard-multiline-static"
                  label="Description"
                  multiline
                  rows={6}
                  size="small"
                  inputProps={{ maxLength: 255 }}
                  className="textArea"
                  variant="standard"
                  value={contentDetail.description}
                  focused
                  sx={{ width: "100%", margin: "15px 0 0 0" }}
                  onChange={(e) => {
                    setContentDetail({
                      ...contentDetail,
                      description: e.target.value,
                    });
                  }}
                />
              </TextFieldContainer>
            </DisplayLeftContainer>
          </Box>
        </Box>
      </Box>
    );
  };

  const processContentUpload = async (data) => {
    const nonResizedImgReader = new FileReader();
    nonResizedImgReader.readAsDataURL(data.file[0]);
    // Resize image
    Resizer.imageFileResizer(
      data.file[0],
      400,
      400,
      "JPEG",
      100,
      0,
      (uri) => {
        // resolve(uri);
        console.log("uri", uri);
        // setResizedFile(uri);
        reader.readAsDataURL(uri);
      },
      "file"
    );

    console.log("processContentUpload", data);
    setPicUpload([...picUpload, data.file[0]]);
    // console.log("content state", contentUpload);
    const reader = new FileReader();
    reader.onload = (e) => {
      console.log("resized image", e.target.result);
      setImg(e.target.result);
    };

    nonResizedImgReader.onload = (e) => {
      console.log("data file 0: ", data.file[0]);
      console.log("non resized: ", e.target.result);
      setNonResizedImg(e.target.result);
      setShowDisplayPic(true);
      setShowThumbnailContainer(true);
      setShowSourceText(true);
    };
  };

  const processSourceUpload = async (data) => {
    console.log(data);
    setSourceUpload([data.file2[0]]);
    console.log("contentUploadState", picUpload);
    setShowSourceText(false);
  };

  const removeSource = () => {
    setSourceUpload([]);
    setShowSourceText(true);
  };

  const handleDownload = async () => {
    // let imgZip = jszip.folder("images");
    picUpload.map(async (file) => {
      // console.log("zipping", file);
      jszip.file(file.name, file, { base64: true });
    });
    const content = await jszip.generateAsync({ type: "blob" });
    saveAs(content, "example.zip");
    // cosole.log(content);
  };

  const filterUploadList = (fileName) => {
    // console.log("filtering...", fileName);
    setPicUpload(picUpload.filter((file) => file.name != fileName));
  };

  const removeImg = () => {
    setImg(null);
    setShowDisplayPic(false);
    setShowThumbnailContainer(false);
    setShowSourceText(false);
  };

  const onSubmit = (data) => console.log(data);
  const RenderContentComponent = () => {
    return (
      <>
        {/* <TitleText>Upload Content</TitleText> */}
        <ContentUploadContainer className="contentUploadContainer">
          <Box
            className={
              showDisplayPic
                ? classes.displayImgContainer
                : classes.hideDisplayImgContainer
            }
          >
            {" "}
            <Box
              className={
                showDisplayPic ? "removeDisplayPic" : "hideSelectImgLabel"
              }
            >
              <Box
                onClick={removeImg}
                sx={{
                  margin: "0 10px 10px 0",
                  backgroundColor: "#393652",
                  color: "#eee",
                  /* padding: 20px 40px; */
                  borderRadius: "25%",
                  fontWeight: "700",
                  fontSize: "17px",
                  width: "35px",
                  height: "35px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                X
              </Box>
            </Box>
          </Box>
          <form
            class="displayForm"
            onChange={handleSubmit(processContentUpload)}
          >
            <input
              {...register("file")}
              id="display"
              type="file"
              name="file"
              class="displayInput"
            />
            <label
              for="display"
              class={showDisplayPic ? "hideSelectImgLabel" : "selectImgLabel"}
            >
              Select JPEG, GIF or PNG
            </label>
          </form>
          <Box
            className="addSourceFileContainer"
            sx={{
              width: "600px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box className="processUploadContainer">
              <form onChange={handleSubmit(processSourceUpload)}>
                <input
                  {...register("file2")}
                  type="file"
                  name="file2"
                  id="source"
                  class="addSourceInput"
                />
                <label
                  for="source"
                  class={showSourceText ? "addSource" : "removeAddSource"}
                >
                  Add Source File
                </label>
              </form>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {sourceUpload.map((file) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "600px",
                    justifyContent: "space-between",
                  }}
                >
                  <div class="addSource">{file.name}</div>{" "}
                  <Box
                    // onClick={() => filterUploadList(file.name)}
                    onClick={() => removeSource()}
                    sx={{
                      margin: "0 9px 0 0",
                      backgroundColor: "#393652",
                      color: "#eee",
                      /* padding: 20px 40px; */
                      borderRadius: "25%",
                      fontWeight: "700",
                      fontSize: "17px",
                      width: "35px",
                      height: "35px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    X
                  </Box>
                </Box>
              );
            })}
          </Box>
          {/* <button onClick={handleDownload}>Download Zip</button> */}
        </ContentUploadContainer>
      </>
    );
  };

  const RenderGuideline = () => {
    return (
      <Box
        className="outerGuideLineContainer"
        sx={{ width: "60%", maxWidth: "1030px" }}
      >
        <TitleText>Guideline</TitleText>
        <GuidelineContainer className="guideLineContainer">
          <GuidelineText sx={{ fontWeight: "600" }}>
            Please note that the following contents are against our terms of
            usage:
          </GuidelineText>
          <GuidelineText>R18+ </GuidelineText>
          <GuidelineText> Copyrighted content</GuidelineText>
          <GuidelineText>
            Content that violate the Terms of Use will result in an account
            suspension and possible termination of your account.
          </GuidelineText>
        </GuidelineContainer>
      </Box>
    );
  };

  // const getCropData = () => {
  //   if (cropper !== "undefined") {
  //     console.log("cropped canvas", cropper.getCroppedCanvas());
  //     setCropData(cropper.getCroppedCanvas().toDataURL());
  //     setThumbnailUpload(cropper.getCroppedCanvas().toDataURL());
  //   }
  // };

  const initiallizeCropper = (instance) => {
    // console.log("inside initializeCropper");
    setCropper(instance);
  };

  const handleSubmission = async () => {
    // Crop thumbnail for upload
    const cropData = cropper.getCroppedCanvas().toDataURL();

    // Upload to Storage
    const storage = getStorage(app);
    const storageRefPic = ref(
      storage,
      "user_content/" + currentUser.uid + "/picture/" + picUpload[0].name
    );
    const storageRefSource = ref(
      storage,
      "user_content/" + currentUser.uid + "/source/" + sourceUpload[0].name
    );
    await uploadBytes(storageRefPic, picUpload[0]);
    await uploadBytes(storageRefSource, sourceUpload[0]);

    // Get URL and update to DB
    const picUrl = await getDownloadURL(
      ref(
        storage,
        "user_content/" + currentUser.uid + "/picture/" + picUpload[0].name
      )
    );
    const sourceUrl = await getDownloadURL(
      ref(
        storage,
        "user_content/" + currentUser.uid + "/source/" + sourceUpload[0].name
      )
    );
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    // xhr.onload = (event) => {
    //   const blob = xhr.response;
    // };
    xhr.open("GET", picUrl);
    xhr.send();

    // Creates reference info to 2 collections and upload to DB
    const userPublicationRef = doc(
      collection(db, "user", currentUser.uid, "unprocessedPublication")
    );

    await setDoc(userPublicationRef, {
      picUrl: picUrl,
      sourceUrl: sourceUrl,
      uid: currentUser.uid,
      caption: contentDetail.caption,
      description: contentDetail.description,
      standardLicense: contentDetail.standardLicense,
      premLicense: contentDetail.premLicense,
      userDocRefID: userPublicationRef.id,
      thumbnail: cropData,
      artistUsername: userDBInfo.username,
      artistProfilePic: userDBInfo.profile_pic_addr,
    });

    // const adminPublicationReviewRef = doc(collection(db, "publicationReview"));
    // const

    await setDoc(doc(db, "publicationReview", userPublicationRef.id), {
      picUrl: picUrl,
      sourceUrl: sourceUrl,
      uid: currentUser.uid,
      caption: contentDetail.caption,
      description: contentDetail.description,
      standardLicense: contentDetail.standardLicense,
      premLicense: contentDetail.premLicense,
      userDocRefID: userPublicationRef.id,
      publicationReviewRefID: userPublicationRef.id,
      thumbnail: cropData,
      artistUsername: userDBInfo.username,
      artistProfilePic: userDBInfo.profile_pic_addr,
    });

    // Reset form
    // setContentDetail({
    //   caption: "",
    //   description: "",
    //   standardLicense: "",
    //   premLicense: "",
    // });
    // setPicUpload([]);
    // setSourceUpload([]);
    // setImg(null);
    // setShowDisplayPic(false);
    // setShowThumbnailContainer(false);
    // setShowSourceText(false);
  };

  return (
    userDBInfo && (
      <Box
        className="newPublicationContainer"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <RenderContentComponent />
        <Box
          className={
            //   showThumbnailContainer
            true ? "thumbnailContainer" : "hideThumbnailContainer"
          }
        >
          <Box
            sx={{
              padding: "25px",
              width: "60%",
              // backgroundColor: "lightblue",
              borderRadius: "5px",
              display: "flex",
              margin: "0 auto",
              justifyContent: "space-between",
              maxWidth: "1030px",
            }}
          >
            <Box
              className="leftThumbnailContainer"
              sx={{ display: "flex", flexDirection: "column", width: "45%" }}
            >
              <Box
                sx={{
                  fontSize: "14px",
                  fontWeight: "600",
                  margin: "0 0 5px 3px",
                }}
              >
                Thumbnail
              </Box>
              <Cropper
                src={img}
                style={{
                  height: 300,
                  width: 300,
                  borderRadius: "8px",
                  backgroundColor: "yellow",
                  overflow: "hidden",
                }}
                guides={false}
                dragMode="none"
                viewMode={3}
                minCropBoxHeight={10}
                minCropBoxWidth={10}
                initialAspectRatio={1}
                cropBoxResizable={false}
                movable={true}
                preview=".img-preview"
                autoCropArea={1}
                //   crossOrigin={"true"}
                responsive={1}
                checkOrientation={false}
                onInitialized={(instance) => {
                  initiallizeCropper(instance);
                }}
              />
              <Box
                className="cropperTitle"
                sx={{
                  fontWeight: "600",
                  margin: "5px 0 0 0",
                  fontFamily: "monospace",
                  fontSize: "15px",
                  width: "290px",
                }}
              >
                {contentDetail.caption}
              </Box>
            </Box>
            <Box className="contentDescription" sx={{ width: "50%" }}>
              {RenderThumbnailComponent()}
            </Box>
          </Box>
        </Box>
        {/* <Box className={true ? "previewContainer" : "hidePreviewContainer"}>
          <div className={true ? "img-preview" : "img-preview-hidden"}></div>
          <div>
            <img
              style={{
                width: "300px",
                height: "300px",
                backgroundColor: "grey",
                borderRadius: "10px",
              }}
              src={cropData}
            />
            <button onClick={getCropData}>crop image</button>
          </div>
        </Box> */}
        {RenderLicenseComponent()}
        <RenderGuideline />

        <Box
          sx={{
            color: "#fff",
            backgroundColor: "#393652",
            padding: "15px 25px",
            borderRadius: "5px",
            margin: "0 0 50px 0",
            cursor: "pointer",
          }}
          onClick={handleSubmission}
        >
          Publish
        </Box>
      </Box>
    )
  );
}

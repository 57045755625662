import React, { useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import { styled } from "@mui/system";
import NewPublication from "./NewPublication";
import UploadApplication from "./UploadApplication";
import PublicationCollection from "./PublicationCollection";
import Box from "@mui/material/Box";

export default function ({ userDBInfo }) {
  const [navValue, setNavValue] = useState(0);

  useEffect(() => {
    // console.log("Uplaods.js", userDBInfo);
  }, []);

  const RenderNavContent = () => {
    switch (navValue) {
      case 0:
        return <PublicationCollection userDBInfo={userDBInfo} />;
        break;
      case 1:
        // return <NewPublication userDBInfo={userDBInfo} />;
        return <UploadApplication />;
        break;
    }
  };

  return (
    <Box
      className="uploadsContainer"
      sx={{
        display: "flex",
        //   justifyContent: "center",
        alignItems: "flex-start",
        justifyContent: "center",
        flexDirection: "row",
        backgroundColor: "#f5f5f5",
        width: "100%",
        margin: "0 0 55px 0",
        minHeight: "100vh",
      }}
    >
      {/* <Box
        className="UploadsNavContainer"
        sx={{
          width: "15%",
          display: "flex",
          flexDirection: "column",
          margin: "0 25px 0 0",
          backgroundColor: "#fff",
          color: "#181818",
          minHeight: "100vh",
        }}
      >
        <Box onClick={() => setNavValue(0)}>Publications</Box>
        <Box onClick={() => setNavValue(1)}>Upload New</Box>
      </Box> */}
      <RenderNavContent />
      {/* <button onClick={handleDownload}> download zip file</button>  */}
    </Box>
  );
}

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavigationTop from "./NavigationTop";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import { styled } from "@mui/system";

const useStyles = makeStyles({
  displayImgContainer: (props) => ({
    width: "650px",
    height: "650px",
    backgroundSize: "contain",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    // backgroundColor: "#646464",
    backgroundColor: "#ececec",
    // margin: "0 0 20px 0",
    backgroundImage: props.backgroundImage,
    borderRadius: "5px",
  }),
});

const ProfilePic = styled("img")({
  width: "50px",
  height: "50px",
  borderRadius: "50%",
  backgroundColor: "white",
});

const LicenseInfoTooltip = styled("div")({
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  backgroundColor: "#3796ca",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 0 0 10px",
  fontSize: "14px",
  fontWeight: "700",
});

export default function ({ tileInfo, setShowModal }) {
  const location = useLocation();

  //   Passing state to CSS to render backgroundImg
  const props = {
    backgroundImage: "url(" + tileInfo.watermarkPic + ")",
  };

  const classes = useStyles(props);

  useEffect(() => {
    // console.log("pubdetails:", tileInfo);
  }, []);

  const handleKeyDown = (e) => {
    console.log("key down");
    if (e.key === "Escape") {
      setShowModal(false);
    }
  };

  return (
    <Box
      className="pubDetailContainer"
      sx={{
        backgroundColor: "rgb(24 24 24 / 35%)",
        top: "60px",
        bottom: "0",
        left: "0",
        right: "0",
        position: "fixed",
      }}
    >
      <Box
        className="pubDetailContainer2"
        sx={{
          // backgroundColor: "#282828",
          backgroundColor: "#f7f7f7",
          position: "fixed",
          zIndex: "10",
          top: "68px",
          bottom: "8px",
          left: "8px",
          right: "8px",
          borderRadius: "10px",
          display: "flex",
        }}
      >
        <Box
          className="modal_imgCon"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "60%",
            alignItems: "flex-end",
            margin: "0 25px 0 0",
            outline: "none",
          }}
          onKeyDown={handleKeyDown}
          tabIndex="0"
        >
          {/* <img src={tileInfo.watermarkPic} />  */}
          <Box className={classes.displayImgContainer}></Box>
          <Box
            className="imgInfo_container"
            sx={{ width: "650px"}}
          >
            <Box
              className="pubDetails_captionDescription"
              sx={{ maxWidth: "450px" }}
            >
              <Box
                sx={{
                  fontWeight: "600",
                  fontFamily: "monospace",
                  fontSize: "18px",
                  margin: "0 0 5px 0",
                }}
              >
                Caption: {tileInfo.caption}
              </Box>
              <Box
                sx={{
                  fontSize: "15px",
                  fontWeight: "500",
                  margin: "0 0 10px 0",
                }}
              >
                {" "}
                Description: {tileInfo.description} Lorem ipsum dolor sit amet,
                consectetur adipiscing elit. Donec maximus cursus leo, et
                ultricies nunc congue nec. Duis sollicitudin felis tellus, in
                facilisis magna convallis eget. Donec vel ornare metus. Sed
                imperdiet sapien feugiat, volutpat tortor ut, mattis neque.
                Phasellus sit amet elementum ex. Fusce finibus augue sit amet
                mollis viverra. Nam neque orci, luctus eget finibus auctor,
                bibendum non eros. Vivamus ut felis dictum, rutrum nulla ut,
                vulputate ex
              </Box>
              <Box sx={{ display: "flex", margin: "5px 0 25px" }}>
                <FavoriteBorderRoundedIcon
                  sx={{ fontSize: "35px", cursor: "pointer" }}
                />
                <FavoriteRoundedIcon
                  sx={{ fontSize: "35px", color: "#ad0808" }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className="pubInfoContainer"
          sx={{
            // color: "#f7f7f7",
            color: "#2a2a2a",
            width: "40%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "40%",
            outline: "none",
          }}
          onKeyDown={handleKeyDown}
          tabIndex="0"
        >
          <Box
            className="pubInfoContainer2"
            sx={{
              margin: "0 0 0 25px",
              // backgroundColor: "#282828",
              backgroundColor: "#f7f7f7",
              height: "650px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ProfilePic src={tileInfo.artistProfilePic} />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ margin: "0 10px 0 5px", fontWeight: "700" }}>
                    {tileInfo.artistUsername}
                  </Box>
                  <Box
                    sx={{
                      width: "65px",
                      height: "20px",
                      backgroundColor: "#7c43e4",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "30px",
                      fontSize: "14px",
                      fontWeight: "600",
                      margin: "3px 0 0 5px",
                      color: "#f7f7f7",
                    }}
                  >
                    Follow
                  </Box>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    margin: "20px 0 0 0",
                    fontSize: "13px",
                    fontWeight: "500",
                    display: "flex",
                    width: "310px",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>More from the same artist...</Box>
                  <Box>View all</Box>
                </Box>
                <Box
                  className="pubDetails_otherWorksContainer"
                  sx={{ margin: "5px 0 0 0", display: "flex" }}
                >
                  <Box
                    sx={{
                      width: "100px",
                      height: "100px",
                      backgroundColor: "#fff",
                      borderRadius: "5px",
                      margin: "0 5px 0 0",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      width: "100px",
                      height: "100px",
                      backgroundColor: "#fff",
                      borderRadius: "5px",
                      margin: "0 5px 0 0",
                    }}
                  ></Box>
                  <Box
                    sx={{
                      width: "100px",
                      height: "100px",
                      backgroundColor: "#fff",
                      borderRadius: "5px",
                      margin: "0 5px 0 0",
                    }}
                  ></Box>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box
                className="pubDetails_captionDescription"
                sx={{ maxWidth: "450px" }}
              >
                <Box
                  sx={{
                    fontWeight: "600",
                    fontFamily: "monospace",
                    fontSize: "18px",
                    margin: "0 0 5px 0",
                  }}
                >
                  Caption: {tileInfo.caption}
                </Box>
                <Box
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    margin: "0 0 10px 0",
                  }}
                >
                  {" "}
                  Description: {tileInfo.description}
                </Box>
                <Box sx={{ display: "flex", margin: "5px 0 25px" }}>
                  <FavoriteBorderRoundedIcon
                    sx={{ fontSize: "35px", cursor: "pointer" }}
                  />
                  <FavoriteRoundedIcon
                    sx={{ fontSize: "35px", color: "#ad0808" }}
                  />
                </Box>
              </Box>
              <Box
                sx={{ width: "310px", height: "1px", backgroundColor: "#fff" }}
              ></Box>
              <Box sx={{ margin: "20px 0 0 0" }}>
                <Box
                  sx={{
                    fontWeight: "500",
                    margin: "0 0 6px 0",
                    fontSize: "15px",
                    display: "flex",
                  }}
                >
                  $ {tileInfo.standardLicense} - Standard License
                  <Tooltip
                    title={
                      <Typography variant="body2" sx={{ width: "300px" }}>
                        Premium Illustration License inclues all the usage
                        rights of our Standard Illustration License. It also
                        enables unlimited web distribution, print copies,
                        packaging copies, OOH advertising impressions,
                        merchandising, video production, and decoration in
                        commercial spaces.
                      </Typography>
                    }
                    placement="top"
                  >
                    <LicenseInfoTooltip>i</LicenseInfoTooltip>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    fontWeight: "500",
                    margin: "0 0 20px 0",
                    fontSize: "15px",
                    display: "flex",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  $ {tileInfo.premLicense} - Premium Illustration License
                  <Tooltip
                    title={
                      <Typography variant="body2" sx={{ width: "300px" }}>
                        Premium Illustration License inclues all the usage
                        rights of our Standard Illustration License. It also
                        enables unlimited web distribution, print copies,
                        packaging copies, OOH advertising impressions,
                        merchandising, video production, and decoration in
                        commercial spaces.
                      </Typography>
                    }
                    placement="top"
                  >
                    <LicenseInfoTooltip>i</LicenseInfoTooltip>
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    width: "105px",
                    height: "35px",
                    backgroundColor: "#7c43e4",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "14px",
                    fontWeight: "600",
                    color: "#f7f7f7",
                  }}
                >
                  Add to Cart
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className="closeModal"
          sx={{
            color: "#3c3c3c",
            width: "50px",
            height: "50px",
            padding: "10px",
            borderRadius: "50%",
            fontSize: "25px",
            fontWeight: "800",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => setShowModal(false)}
        >
          X
        </Box>
      </Box>
    </Box>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../src/GeneralComponents/Home";
import { AuthContext } from "./Auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import NavigationTop from "./GeneralComponents/NavigationTop";
import PrivateRoute from "./PrivateRoute";
import UserHome from "./UserComponents/UserHome";
import LoginSignup from "./GeneralComponents/LoginSignup";
import app from "./firebase";

// Admin Components
import UploadApplicationReview from "./AdminComponents/UploadApplicationReview";
import PublicationReview from "./AdminComponents/PublicationReview";
// General Components
import PublicationDetails from "./GeneralComponents/publicationDetails_dynamicPage";

function App() {
  const { currentUser } = useContext(AuthContext);
  const [userDBInfo, setUserDBInfo] = useState(null);

  useEffect(async () => {
    if (!!currentUser) {
      const db = getFirestore(app);
      const dbRef = doc(db, "user", currentUser.uid);
      const docSnap = await getDoc(dbRef);
      setUserDBInfo(docSnap.data());
      console.log("home", docSnap.data());
    }
  }, [currentUser]);

  return (
    <BrowserRouter>
      <NavigationTop userDBInfo={userDBInfo} setUserDBInfo={setUserDBInfo} />
      <Routes>
        {/* Admin Routes */}
        <Route
          path="/applicationreview"
          element={<UploadApplicationReview />}
        />
        <Route path="/publicationreview" element={<PublicationReview />} />
        {/* General Routes */}
        <Route exact path="/" element={<Home />} />
        <Route exact path="/login" element={<LoginSignup />} />
        <Route element={<PrivateRoute />}>
          <Route path="/UserHome" element={<UserHome />} />
        </Route>
        <Route path="*" element={<Home />} />
        <Route
          path="publications/:userId/:pubId"
          element={<PublicationDetails />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

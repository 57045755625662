import logo from "./logo.svg";
import "./App.css";
import { useEffect } from "react";

import { styled } from "@mui/system";

const PIXI_container = styled("canvas")({
  display: "block",
});

function App() {
  useEffect(async () => {
    const script = document.createElement("script");
    script.src = "script.js";
    script.async = true;
    document.body.appendChild(script);
    // return () => {
    //   document.body.removeChild(script);
    // };
  }, []);
  return <PIXI_container id="canvas"></PIXI_container>;
}

export default App;

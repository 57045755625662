import React, { useContext, useEffect } from "react";
import { styled } from "@mui/system";
import { AuthContext } from "../Auth";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const NavContainer = styled("div")({
  backgroundColor: "#393652",
  width: "100%",
  height: "60px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const LogoContainer = styled("div")({
  height: "60px",
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
});

const LogoText = styled("div")({
  color: "#f7f7f7",
  fontFamily: "Noto serif",
  fontWeight: "600",
  fontSize: "25px",
});

export default function () {
  const { currentUser } = useContext(AuthContext);
  const auth = getAuth();

  useEffect(() => {}, []);

  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };
  
  return (
    <NavContainer>
      <LogoContainer onClick={goHome}>
        <LogoText>Anipixr</LogoText>
      </LogoContainer>
    </NavContainer>
  );
}

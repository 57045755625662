import React, { useContext, useEffect, useState } from "react";
import NavigationTop from "./NavigationTop";
import Home_Tiles from "./Home_Tiles";
import BannerContainer from "./BannerContainer";
import { AuthContext } from "../Auth";
import { useNavigate } from "react-router-dom";
import Div100vh from "react-div-100vh";
import { styled } from "@mui/system";
import { doc, getDoc, updateDoc, getFirestore } from "firebase/firestore";
import app from "../firebase";

const Container = styled("div")({});

export default function () {
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [userDBInfo, setUserDBInfo] = useState(null);

  useEffect(async () => {
    if (!!currentUser) {
      const db = getFirestore(app);
      const dbRef = doc(db, "user", currentUser.uid);
      const docSnap = await getDoc(dbRef);
      setUserDBInfo(docSnap.data());
      console.log("home", docSnap.data());
      // navigate("/userhome");
    }
  }, [currentUser]);

  return (
    <Container>
      {/* <NavigationTop userDBInfo={userDBInfo} setUserDBInfo={setUserDBInfo} /> */}
      <Home_Tiles userDBInfo={userDBInfo} setUserDBInfo={setUserDBInfo} />
    </Container>
  );
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { styled } from "@mui/system";
import {
  doc,
  updateDoc,
  setDoc,
  deleteDoc,
  getFirestore,
  collection,
  getDocs,
  addDoc,
  getDoc,
} from "firebase/firestore";
import app from "../firebase";
import Box from "@mui/material/Box";
import { makeStyles } from "@material-ui/core";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import FavoriteRoundedIcon from "@mui/icons-material/FavoriteRounded";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles({
  displayImgContainer: (props) => ({
    width: "700px",
    height: "700px",
    backgroundSize: "contain",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    backgroundColor: "#646464",
    // backgroundColor: "#ececec",
    // margin: "0 0 20px 0",
    backgroundImage: props.backgroundImage,
    borderRadius: "5px 5px 0 0",
  }),
});

const ProfilePic = styled("img")({
  width: "60px",
  height: "60px",
  borderRadius: "50%",
  backgroundColor: "white",
});

const LicenseInfoTooltip = styled("div")({
  width: "20px",
  height: "20px",
  borderRadius: "50%",
  backgroundColor: "#3796ca",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 0 0 10px",
  fontSize: "14px",
  fontWeight: "700",
});

export default function () {
  const { pubId } = useParams();
  const [pubInfo, setPubInfo] = useState(null);
  const [watermarkImg, setWatermarkImg] = useState(null);

  //   Passing state to CSS to render backgroundImg
  const props = {
    backgroundImage: "url(" + watermarkImg + ")",
  };
  const classes = useStyles(props);

  //   Global Firebase info
  const db = getFirestore(app);

  useEffect(async () => {
    const pubRef = doc(db, "publications", pubId);
    const pubSnap = await getDoc(pubRef);

    console.log("pub snap: ", pubSnap.data());
    setPubInfo(pubSnap.data());
    setWatermarkImg(pubSnap.data().watermarkPic);
  }, [watermarkImg]);

  return (
    <Box
      className="pubDetails_outerContainer"
      sx={{
        margin: "60px 0 0 0",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#fafafa",
      }}
    >
      {pubInfo && (
        <Box
          className="pubDetailContainer2"
          sx={{
            display: "flex",
            margin: "40px 0 0 0",
          }}
        >
          <Box
            className="modal_imgCon"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "60%",
              alignItems: "flex-end",
              margin: "0 25px 0 0",
              outline: "none",
            }}
            tabIndex="0"
          >
            {/* <img src={tileInfo.watermarkPic} />  */}
            <Box className={classes.displayImgContainer}></Box>
            {/* <Box sx={{ display: "flex", margin: "5px 0 25px" }}>
              <FavoriteBorderRoundedIcon
                sx={{ fontSize: "35px", cursor: "pointer" }}
              />
              <FavoriteRoundedIcon
                sx={{ fontSize: "35px", color: "#ad0808" }}
              />
            </Box> */}
            <Box
              className="imgInfo_container"
              sx={{
                width: "700px",
                backgroundColor: "#ffffff",
                margin: "0 0 80px 0",
              }}
            >
              <Box>
                <Box
                  className="artistProfile_container"
                  sx={{ display: "flex", alignItems: "center", margin: "10px" }}
                >
                  <ProfilePic src={pubInfo.artistProfilePic} />
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ margin: "0 10px 0 5px", fontWeight: "700" }}>
                      {pubInfo.artistUsername}
                    </Box>
                    <Box
                      sx={{
                        width: "65px",
                        height: "20px",
                        backgroundColor: "#7c43e4",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "30px",
                        fontSize: "14px",
                        fontWeight: "600",
                        margin: "3px 0 0 5px",
                        color: "#f7f7f7",
                      }}
                    >
                      Follow
                    </Box>
                  </Box>
                </Box>
                <Box>
                  <Box
                    sx={{
                      margin: "20px 0 0 0",
                      fontSize: "13px",
                      fontWeight: "500",
                      display: "flex",
                      width: "310px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={{ margin: "15px" }}>
                      More from the same artist...
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      View all
                    </Box>
                  </Box>
                  <Box
                    className="pubDetails_otherWorksContainer"
                    sx={{ margin: "15px", display: "flex" }}
                  >
                    <Box
                      sx={{
                        width: "100px",
                        height: "100px",
                        backgroundColor: "#646464",
                        borderRadius: "5px",
                        margin: "0 10px 0 0",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        width: "100px",
                        height: "100px",
                        backgroundColor: "#646464",
                        borderRadius: "5px",
                        margin: "0 10px 0 0",
                      }}
                    ></Box>
                    <Box
                      sx={{
                        width: "100px",
                        height: "100px",
                        backgroundColor: "#646464",
                        borderRadius: "5px",
                        margin: "0 10px 0 0",
                      }}
                    ></Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            className="pubInfoContainer"
            sx={{
              // color: "#f7f7f7",
              color: "#2a2a2a",
              width: "40%",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              width: "40%",
              outline: "none",
            }}
            tabIndex="0"
          >
            <Box
              className="pubInfoContainer2"
              sx={{
                margin: "0 0 0 25px",
                // backgroundColor: "#282828",
                //   backgroundColor: "#f7f7f7",
                height: "650px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Box
                  className="pubDetails_captionDescription"
                  sx={{ maxWidth: "450px" }}
                >
                  <Box
                    className="pubDetails_caption"
                    sx={{
                      fontWeight: "600",
                      fontFamily: "monospace",
                      fontSize: "20px",
                      margin: "0 0 10px 0",
                    }}
                  >
                    Caption: {pubInfo.caption}
                  </Box>
                  <Box
                    sx={{
                      fontSize: "15px",
                      fontWeight: "500",
                      margin: "0 0 10px 0",
                    }}
                  >
                    {" "}
                    {/* Description: {pubInfo.description} */}
                    Description: Lorem Ipsum is simply dummy text of the
                    printing and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an
                    unknown printer took a galley of type and scrambled it to
                    make a type specimen book.
                  </Box>
                  <Box
                    sx={{
                      fontSize: "15px",
                      fontWeight: "500",
                      margin: "0 0 10px 0",
                    }}
                  >
                    {" "}
                    Size: 3000 x 3000 pixels
                  </Box>
                  <Box
                    sx={{
                      fontSize: "15px",
                      fontWeight: "500",
                      margin: "0 0 10px 0",
                    }}
                  >
                    {" "}
                    Source File: Photoshop (.psd)
                  </Box>
                  <Box
                    sx={{
                      fontSize: "15px",
                      fontWeight: "500",
                      margin: "0 0 10px 0",
                    }}
                  >
                    {" "}
                    This package contains X files:
                  </Box>
                  <Box
                    sx={{
                      margin: "0 0 25px 25px",
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: "15px",
                        fontWeight: "500",
                        margin: "0 0 5px 0",
                      }}
                    >
                      {" "}
                      background and character image
                    </Box>
                    <Box
                      sx={{
                        fontSize: "15px",
                        fontWeight: "500",
                        margin: "0 0 5px 0",
                      }}
                    >
                      {" "}
                      background image
                    </Box>
                    <Box
                      sx={{
                        fontSize: "15px",
                        fontWeight: "500",
                        margin: "0 0 5px 0",
                      }}
                    >
                      {" "}
                      character image
                    </Box>
                  </Box>
                  <Box sx={{ fontSize: "15px", display: "flex" }}>
                    <Box sx={{ fontSize: "13px", margin: "0 3px 0 3px" }}>
                      #Tag
                    </Box>
                    <Box sx={{ fontSize: "13px", margin: "0 3px 0 3px" }}>
                      #Tag
                    </Box>{" "}
                    <Box sx={{ fontSize: "13px", margin: "0 3px 0 3px" }}>
                      #Tag
                    </Box>{" "}
                    <Box sx={{ fontSize: "13px", margin: "0 3px 0 3px" }}>
                      #Tag
                    </Box>{" "}
                    <Box sx={{ fontSize: "13px", margin: "0 3px 0 3px" }}>
                      #Tag
                    </Box>{" "}
                    <Box sx={{ fontSize: "13px", margin: "0 3px 0 3px" }}>
                      #Tag
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "310px",
                    height: "1px",
                    backgroundColor: "#fff",
                  }}
                ></Box>
                <Box sx={{ margin: "20px 0 0 0" }}>
                  <Box
                    sx={{
                      fontWeight: "500",
                      margin: "0 0 6px 0",
                      fontSize: "15px",
                      display: "flex",
                    }}
                  >
                    $ {pubInfo.standardLicense} - Standard License
                    <Tooltip
                      title={
                        <Typography variant="body2" sx={{ width: "300px" }}>
                          Premium Illustration License inclues all the usage
                          rights of our Standard Illustration License. It also
                          enables unlimited web distribution, print copies,
                          packaging copies, OOH advertising impressions,
                          merchandising, video production, and decoration in
                          commercial spaces.
                        </Typography>
                      }
                      placement="top"
                    >
                      <LicenseInfoTooltip>i</LicenseInfoTooltip>
                    </Tooltip>
                  </Box>
                  <Box
                    sx={{
                      fontWeight: "500",
                      margin: "0 0 20px 0",
                      fontSize: "15px",
                      display: "flex",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    $ {pubInfo.premLicense} - Premium Illustration License
                    <Tooltip
                      title={
                        <Typography variant="body2" sx={{ width: "300px" }}>
                          Premium Illustration License inclues all the usage
                          rights of our Standard Illustration License. It also
                          enables unlimited web distribution, print copies,
                          packaging copies, OOH advertising impressions,
                          merchandising, video production, and decoration in
                          commercial spaces.
                        </Typography>
                      }
                      placement="top"
                    >
                      <LicenseInfoTooltip>i</LicenseInfoTooltip>
                    </Tooltip>
                  </Box>
                  <Box
                    sx={{
                      width: "105px",
                      height: "35px",
                      backgroundColor: "#7c43e4",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontSize: "14px",
                      fontWeight: "600",
                      color: "#f7f7f7",
                    }}
                  >
                    Add to Cart
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          {/* <Box
              className="closeModal"
              sx={{
                color: "#3c3c3c",
                width: "50px",
                height: "50px",
                padding: "10px",
                borderRadius: "50%",
                fontSize: "25px",
                fontWeight: "800",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              X
            </Box> */}
        </Box>
      )}
    </Box>
  );
}

import React, { useState, useContext, useEffect } from "react";
import app from "../firebase";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc, getFirestore } from "firebase/firestore";
import { AuthContext } from "../Auth";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useForm } from "react-hook-form";
import Div100vh from "react-div-100vh";
import "./profile.css";

const ProfileContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  backgroundColor: "#f5f5f5",
  margin: "40px auto 0",
  borderRadius: "10px",
});

const ProfileAndInfoContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
  maxWidth: "1200px",
  width: "80%",
  borderRadius: "10px",
  backgroundColor: "#f5f5f5",
});

const ProfilePicContainer = styled("div")({
  width: "250px",
  height: "300px",
  borderRadius: "10px",
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "center",
  padding: "25px 0 0 0",
  margin: "0 25px 0 0",
});

const ProfilePic = styled("img")({
  display: "flex",
  justifyContent: "center",
  width: "150px",
  height: "150px",
  borderRadius: "50%",
  filter: "drop-shadow(-1px 1px 4px gray)",
});

const UserInfoContainer = styled("div")({
  width: "32%",
  minWidth: "350px",
  maxWidth: "1000px",
  height: "400px",
  backgroundColor: "#fff",
  borderRadius: "10px",
  padding: "25px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const TextFieldContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const AccountInfoText = styled("div")({
  fontSize: "25px",
  fontWeight: "500",
  color: "#374960",
  margin: "10px 0 20px 0",
});

export default function ({ userDBInfo, setUserDBInfo }) {
  const { currentUser } = useContext(AuthContext);
  const [uploadFile, setUploadFile] = useState([]);
  const [profilePic, setProfilePic] = useState(null);
  const [userInfo, setUserInfo] = useState();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  //   Global Firebase info
  const db = getFirestore(app);
  const dbRef = doc(db, "user", currentUser.uid);

  useEffect(async () => {
    setProfilePic(userDBInfo.profile_pic_addr);
  }, []);

  const handleProcessFile = (data) => {
    // console.log(data);
    setUploadFile(data.profilePic[0]);
    const reader = new FileReader();
    reader.onload = (e) => {
      // console.log(e.target.result);
      setProfilePic(e.target.result);
    };
    reader.readAsDataURL(data.profilePic[0]);
  };

  const handleSaveChanges = async () => {
    // console.log("handleSaveChanges");

    //   Upload to Storage
    const storage = getStorage(app);
    const storageRef = ref(
      storage,
      "profile_pic/" + currentUser.uid + "/profile_pic"
    );
    const metadata = {
      contentType: "image/jpeg",
    };
    await uploadBytes(storageRef, uploadFile);

    // Get URL of new profile pic and update DB with new URL
    const url = await getDownloadURL(
      ref(storage, "profile_pic/" + currentUser.uid + "profile_pic")
    );
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.onload = (event) => {
      const blob = xhr.response;
    };
    xhr.open("GET", url);
    xhr.send();
    setProfilePic(url);

    await updateDoc(dbRef, {
      profile_pic_addr: url,
    });
    setUserDBInfo({ ...userDBInfo, profile_pic_addr: url });

    // Update DB with profile pic name
    await updateDoc(dbRef, {
      profile_pic: currentUser.uid + "profile_pic",
    });
  };

  const RenderProfileContainer = () => {
    return (
      <Box
        className="innerProfilePicContainer"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "225px",
        }}
      >
        <ProfilePic className="profilePicContainer" src={profilePic} />
        <form class="profile_form" onChange={handleSubmit(handleProcessFile)}>
          <input
            {...register("profilePic")}
            id="profilePic"
            type="file"
            name="profilePic"
            class="displayInput"
          />
          <label for="profilePic" class="profile_changePicture">
            Change profile picture
          </label>
        </form>
      </Box>
    );
  };

  const RenderUserInfoContainer = () => {
    return (
      userDBInfo && (
        <UserInfoContainer>
          <AccountInfoText>Account Info</AccountInfoText>
          <TextFieldContainer
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "25ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-basic"
              label="Username"
              variant="outlined"
              value={userDBInfo.username}
              onChange={(e) =>
                setUserDBInfo({ ...userDBInfo, username: e.target.value })
              }
              disabled={true}
            />
            <TextField
              id="outlined-basic"
              label="Email"
              variant="outlined"
              value={userDBInfo.email}
              disabled={true}
            />
          </TextFieldContainer>
        </UserInfoContainer>
      )
    );
  };

  return (
    <Div100vh>
      <ProfileContainer className="profileContainer">
        {userDBInfo && (
          <ProfileAndInfoContainer>
            <ProfilePicContainer>
              <RenderProfileContainer />
            </ProfilePicContainer>
            <RenderUserInfoContainer />
          </ProfileAndInfoContainer>
        )}
      </ProfileContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          margin: "40px auto",
          backgroundColor: "#393652",
          width: "170px",
          height: "50px",
          borderRadius: "5px",
          color: "#f7f7f7",
          fontWeight: "500",
          cursor: "pointer",
        }}
        onClick={handleSaveChanges}
      >
        Save
      </Box>
    </Div100vh>
  );
}

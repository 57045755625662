import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import {
  doc,
  updateDoc,
  setDoc,
  deleteDoc,
  getFirestore,
  collection,
  getDocs,
  addDoc,
} from "firebase/firestore";
import app from "../firebase";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import PublicationDetails from "./PublicationDetails";
import BannerContainer from "./BannerContainer";

// const style = {
//   position: "absolute",
//   top: "50%",
//   left: "50%",
//   transform: "translate(-50%, -50%)",
//   width: 400,
//   bgcolor: "background.paper",
//   border: "2px solid #000",
//   boxShadow: 24,
//   p: 4,
// };

const Container = styled("div")({
  width: "1000px",
  height: "100px",
  margin: "0 auto",
});

const SearchContainer = styled("div")({
  backgroundColor: "lightpink",
  width: "80%",
  height: "50px",
  margin: "0 auto",
});

const ImgTag = styled("img")({
  width: "300px",
  borderRadius: "5px",
  margin: "20px 0 0 0",
});

export default function ({ userDBInfo, setUserDBInfo }) {
  const [tilesArr, setTilesArr] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [showMainPg, setShowMainPg] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [tileInfo, setTileInfo] = useState(null);
  let navigate = useNavigate();

  useEffect(async () => {
    const db = getFirestore(app);
    const querySnapshot = await getDocs(collection(db, "publications"));
    console.log("query snapshot: ", querySnapshot);
    let arr1 = [];
    let arr2 = [];
    let count = 0;
    console.log("queryss", querySnapshot);

    querySnapshot.forEach((doc) => {
      if (count < 3) {
        arr1.push(doc.data());
        console.log("count: ", count);
        count++;
      } else {
        arr2.push(arr1);
        arr1 = [];
        count = 0;
        arr1.push(doc.data());
        console.log("count: ", count);
        count++;
      }
    });
    arr2.push(arr1);
    setTilesArr(...tilesArr, arr2);
  }, []);

  const handlePicClick = (tile) => {
    setTileInfo(tile);
    setShowModal(true);
  };

  const RenderTiles = () => {
    console.log("tiles array", tilesArr);

    return tilesArr.map((tileRow) => (
      <Box
        className="tileImg_container"
        sx={{
          display: "flex",
          justifyContent: "space-evenly",
          cursor: "pointer",
        }}
      >
        {tileRow.map((tile) => (
          // <Link
          //   to={"/publications/" + tile.userDocRefID}
          //   state={{
          //     tileInfo: tile,
          //     userDBInfo: userDBInfo,
          //     // setUserDBInfo: setUserDBInfo,
          //   }}
          // >
          <ImgTag
            onClick={() => {
              let path =
                "http://localhost:3000/publications/" +
                tile.artistUsername +
                "/" +
                tile.userDocRefID;
              window.open(path, "_blank");

              // navigate(
              //   "/publications/" + tile.artistUsername + "/" + tile.userDocRefID
              // );
            }}
            src={tile.thumbnail}
            // onClick={() => handlePicClick(tile)}
          />

          // </Link>
        ))}
      </Box>
    ));
  };

  return (
    <Container>
      <Box>
        <BannerContainer
          className="bannerContainer"
          sx={{ backgroundColor: "#fff" }}
        />
        <SearchContainer> Search </SearchContainer>
        <Box
          className="tilesContainer"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <RenderTiles />
        </Box>
      </Box>
      {showModal ? (
        <PublicationDetails tileInfo={tileInfo} setShowModal={setShowModal} />
      ) : null}
    </Container>
  );
}

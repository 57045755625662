import React, { useEffect, useState, useContext } from "react";
import Box from "@mui/material/Box";
import {
  doc,
  getDoc,
  getFirestore,
  getDocs,
  collection,
  setDoc,
  deleteDoc,
} from "firebase/firestore";
import app from "../firebase";
import { AuthContext } from "../Auth";
import { styled } from "@mui/system";
import { makeStyles } from "@material-ui/core";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

const useStyles = makeStyles({
  textNormal: () => ({
    margin: "0 20px 0 0",
    padding: "20px 30px",
    borderRadius: "26px",
    cursor: "pointer",
  }),
  textSelected: {
    margin: "0 20px 0 0",
    padding: "20px 30px",
    backgroundColor: "#dbdbdb",
    borderRadius: "26px",
    cursor: "pointer",
  },
});

const ImgTag = styled("img")({
  width: "200px",
});

const TitleContainer = styled("div")({
  width: "100%",
  fontSize: "15px",
  fontWeight: "500",
  margin: "25px",
});

const ImgContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  margin: "10px",
});

const RemoveFileButton = styled("div")({
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
});

export default function ({ userDBInfo }) {
  const { currentUser } = useContext(AuthContext);
  const [processingPub, setProcessingPub] = useState(null);
  const [publishedPub, setPublishedPub] = useState(null);
  const [navValue, setNavValue] = useState(0);

  //   Global Firebase info
  const db = getFirestore(app);

  // MUI styling
  const classes = useStyles();

  useEffect(async () => {
    //   Get processing and published documents from DB
    const db = getFirestore(app);
    const publishedSnapshot = await getDocs(
      collection(db, "user", currentUser.uid, "publication")
    );
    const processingSnapshot = await getDocs(
      collection(db, "user", currentUser.uid, "unprocessedPublication")
    );
    let tempProcessingArr = [];
    let tempPublishedArr = [];
    publishedSnapshot.forEach((doc) => {
      console.log("published", doc.data());
      tempPublishedArr.push(doc.data());
    });
    processingSnapshot.forEach((doc) => {
      console.log("unprocessed", doc.data());
      tempProcessingArr.push(doc.data());
    });
    setPublishedPub(tempPublishedArr);
    setProcessingPub(tempProcessingArr);
  }, []);

  const removeProcessingFile = () => {
    console.log("remove processing file");
  };

  const RenderProcessingPublication = () => {
    return (
      <Box
        className="processingPublicationContainer"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          backgroundColor: "#fff",
          padding: "45px 0",
          borderRadius: "20px",
          alignItems: "center",
          width: "100%",
          maxWidth: "1030px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            margin: "20px 0 50px 0",
          }}
        >
          <Box
            sx={{
              flexDirection: "column",
              alignItems: "center",
              lineHeight: "30px",
              display: "flex",
              width: "80%",
              backgroundColor: "#d3f4ff",
              margin: "0 auto",
              padding: "30px",
              borderRadius: "10px",
              fontWeight: "400",
              fontSize: "15px",
              color: "#181818",
            }}
          >
            <Box>Please wait a little. The publications are under review.</Box>
            <Box sx={{ display: "flex" }}>
              They will be published once approved.
              <Box sx={{ fontSize: "25px", margin: "0 0 0 5px" }}>
                &#128522;
              </Box>
            </Box>
          </Box>
        </Box>
        {processingPub.map((doc) => (
          <ImgContainer className="imgContainer">
            <ImgTag src={doc.thumbnail} sx={{ borderRadius: "5px" }} />
            <Box
              sx={{
                width: "100%",
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <DeleteForeverRoundedIcon
                onClick={() => removeProcessingPub(doc)}
                sx={{
                  fontSize: "20px",
                  margin: "3px 3px 0 0",
                  color: "#181818",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#bb0101",
                  },
                }}
              />
            </Box>
          </ImgContainer>
        ))}
      </Box>
    );
  };

  const removeProcessingPub = async (processingPubDocInfo) => {
    console.log("removing from processing");
    // Remove doc from user unprocessedPublication
    await deleteDoc(
      doc(
        db,
        "user",
        processingPubDocInfo.uid,
        "unprocessedPublication",
        processingPubDocInfo.userDocRefID
      )
    );

    // Create doc in user removedPublication
    const userRemovedPublicationRef = doc(
      db,
      "user",
      processingPubDocInfo.uid,
      "removedPublication",
      processingPubDocInfo.userDocRefID
    );
    await setDoc(userRemovedPublicationRef, processingPubDocInfo);

    setProcessingPub(
      processingPub.filter(
        (doc) => doc.userDocRefID != processingPubDocInfo.userDocRefID
      )
    );
  };

  const RenderPublication = () => {
    return (
      <Box
        className="publishedContainer"
        sx={{
          // margin: "0 45px 0 0",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          backgroundColor: "#fff",
          padding: "45px 0",
          borderRadius: "20px",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: "100%",
            margin: "20px 0 50px 0",
          }}
        >
          {publishedPub.length == 0 ? (
            <Box
              sx={{
                flexDirection: "column",
                alignItems: "center",
                lineHeight: "30px",
                display: "flex",
                width: "80%",
                backgroundColor: "#f3f459",
                margin: "0 auto",
                padding: "30px",
                borderRadius: "10px",
                fontWeight: "400",
                fontSize: "15px",
                color: "#181818",
              }}
            >
              <Box>You don't have any live publications yet!</Box>
              <Box>
                Please check back in 24 hours if you have already uploaded
                contents for review.
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                flexDirection: "column",
                alignItems: "center",
                lineHeight: "30px",
                display: "flex",
                width: "80%",
                backgroundColor: "#d3f4ff",
                margin: "0 auto",
                padding: "30px",
                borderRadius: "10px",
                fontWeight: "400",
                fontSize: "15px",
                color: "#181818",
              }}
            >
              <Box sx={{ display: "flex" }}>
                Woohoo! Your publications are live!
                <Box sx={{ fontSize: "25px", margin: "0 0 0 5px" }}>
                  &#128156;{" "}
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {publishedPub.map((doc) => (
          <ImgContainer className="imgContainer">
            <ImgTag src={doc.thumbnail} sx={{ borderRadius: "5px" }} />
            <Box
              sx={{
                width: "100%",
                justifyContent: "flex-end",
                display: "flex",
              }}
            >
              <DeleteForeverRoundedIcon
                onClick={() => removePublication(doc)}
                sx={{
                  fontSize: "20px",
                  margin: "3px 3px 0 0",
                  color: "#181818",
                  cursor: "pointer",
                  "&:hover": {
                    color: "#bb0101",
                  },
                }}
              />
            </Box>
          </ImgContainer>
        ))}
      </Box>
    );
  };

  const removePublication = async (pubDocInfo) => {
    console.log("removing publication");
    // Remove doc from user publication and general publication
    await deleteDoc(
      doc(db, "user", pubDocInfo.uid, "publication", pubDocInfo.userDocRefID)
    );
    await deleteDoc(doc(db, "publications", pubDocInfo.userDocRefID));

    // Create doc in user removedPublication
    const userRemovedPublicationRef = doc(
      db,
      "user",
      pubDocInfo.uid,
      "removedPublication",
      pubDocInfo.userDocRefID
    );
    await setDoc(userRemovedPublicationRef, pubDocInfo);

    setPublishedPub(
      publishedPub.filter((doc) => doc.userDocRefID != pubDocInfo.userDocRefID)
    );
  };

  const RenderWorksBody = () => {
    switch (navValue) {
      case 0:
        return <RenderPublication />;
        break;
      case 1:
        return <RenderProcessingPublication />;
        break;
      default:
        return <Box>Loading</Box>;
    }
    return <div>lol</div>;
  };

  return (
    <Box
      className="userWorksContainer"
      sx={{
        width: "85%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "1030px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          fontSize: "15px",
          fontWeight: "500",
          margin: "25px",
          display: "flex",
        }}
      >
        <Box
          className={navValue == 0 ? classes.textSelected : classes.textNormal}
          onClick={() => setNavValue(0)}
        >
          Published
        </Box>
        <Box
          className={navValue == 1 ? classes.textSelected : classes.textNormal}
          onClick={() => setNavValue(1)}
        >
          Processing
        </Box>
      </Box>
      {processingPub && (
        <>
          <RenderWorksBody />
        </>
      )}
    </Box>
  );
}

import React from "react";
import Test from "../test";
import { styled } from "@mui/system";
import {
  getAuth,
} from "firebase/auth";
import app from "../firebase";
import { useNavigate } from "react-router-dom";

const LolContainer = styled("div")({
  backgroundColor: "yellow",
  width: "700px",
  height: "300px",
});

const BannerContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
});

export default function () {
  const navigate = useNavigate();
  const auth = getAuth(app);

  return (
    <BannerContainer>
      {/* <LolContainer /> */}
      <Test />
    </BannerContainer>
  );
}

import React from "react";
import { styled } from "@mui/system";

const FooterContainer = styled("div")({
  width: "100%",
  height: "50px",
  backgroundColor: "#393652"
});

export default function () {
  return <FooterContainer></FooterContainer>;
}

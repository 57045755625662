import React, { useEffect, useState } from "react";
import NavigationTopV2 from "./NavigationTopV2";
import { styled } from "@mui/system";
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import app from "../firebase";
import { useNavigate, useLocation } from "react-router-dom";
import Div100vh from "react-div-100vh";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import {
  doc,
  setDoc,
  getFirestore,
} from "firebase/firestore";

const LoginSignupContainer = styled(Div100vh)({
  backgroundColor: "#f7f7f7",
  height: "80%",
});

const RenderFormContainer = styled("div")({
  width: "100%",
  height: "90%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
});

const LoginFormContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#fff",
  padding: "25px 50px 50px 50px",
  borderRadius: "10px",
});

const LoginButton = styled(Button)({
  width: "100%",
  margin: "15px 0 0 0",
  borderRadius: "0",
});

const LoginTextField = styled(TextField)({
  margin: "8px 0 !important",
});

const LoginOrSignupContainer = styled("div")({
  display: "flex",
  width: "351px",
  backgroundColor: "#fff",
  borderRadius: "10px 10px 0 0",
});

const Login = styled("div")({
  width: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px 0 20px",
  borderRadius: "5px 0 0 0",
  fontSize: "20px",
  fontWeight: "600",
  cursor: "pointer",
});

const Signup = styled("div")({
  width: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px 0 20px",
  borderRadius: "0 5px 0 0",
  fontSize: "20px",
  fontWeight: "600",
  cursor: "pointer",
});

export default function () {
  const auth = getAuth(app);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [formValue, setFormValue] = useState("login");
  const db = getFirestore(app);

  useEffect(() => {
    setFormValue(state.formValue);
  }, []);

  const [userInfo, setUserLoginInfo] = useState({
    email: "",
    password: "",
  });

  const handleLogin = async () => {
    try {
      await signInWithEmailAndPassword(auth, userInfo.email, userInfo.password);
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  const handleSignup = async () => {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      userInfo.email,
      userInfo.password
    );

    try {
      await setDoc(doc(db, "user", userCredential.user.uid), {
        profile_pic: "ok",
        email: userInfo.email,
        profile_pic_addr:
          "https://firebasestorage.googleapis.com/v0/b/wutok-1c88e.appspot.com/o/profile_pic%2Fdefault_profile_pic.jpg?alt=media&token=6f827cc7-51af-444b-89bf-6ae510698b50",
        username: userInfo.username,
      });
      navigate("/");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <LoginSignupContainer>
      <NavigationTopV2 />
      <RenderFormContainer>
        <LoginOrSignupContainer>
          <Login
            style={{
              boxShadow:
                formValue != "login" ? "inset 0 0 30px #cad0d4" : "none",
              color: formValue != "login" ? "#9c9c9c" : "#374960",
            }}
            onClick={() => setFormValue("login")}
          >
            Log in
          </Login>
          <Signup
            style={{
              boxShadow:
                formValue != "signup" ? "inset 0 0 30px #cad0d4" : "none",
              color: formValue != "signup" ? "#9c9c9c" : "#374960",
            }}
            onClick={() => setFormValue("signup")}
          >
            Sign up
          </Signup>
        </LoginOrSignupContainer>
        <LoginFormContainer
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          {formValue == "login" ? (
            <>
              <LoginTextField
                id="outlined-required"
                label="Email"
                defaultValue=""
                name="email"
                onChange={(e) =>
                  setUserLoginInfo({
                    ...userInfo,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              <LoginTextField
                id="outlined-password-input"
                label="Password"
                type="password"
                name="password"
                onChange={(e) =>
                  setUserLoginInfo({
                    ...userInfo,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              <LoginButton variant="contained" onClick={handleLogin}>
                Log in
              </LoginButton>
            </>
          ) : (
            <>
              <LoginTextField
                id="outlined-required"
                label="Username"
                defaultValue=""
                name="username"
                onChange={(e) =>
                  setUserLoginInfo({
                    ...userInfo,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              <LoginTextField
                id="outlined-required"
                label="Email"
                defaultValue=""
                name="email"
                onChange={(e) =>
                  setUserLoginInfo({
                    ...userInfo,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              <LoginTextField
                id="outlined-password-input"
                label="Password"
                type="password"
                name="password"
                onChange={(e) =>
                  setUserLoginInfo({
                    ...userInfo,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              <LoginButton variant="contained" onClick={handleSignup}>
                Sign up
              </LoginButton>
            </>
          )}
        </LoginFormContainer>
      </RenderFormContainer>
    </LoginSignupContainer>
  );
}

import React, { useContext, useEffect, useState } from "react";
import {
  doc,
  updateDoc,
  setDoc,
  deleteDoc,
  getFirestore,
  collection,
  getDocs,
  addDoc,
} from "firebase/firestore";
import app from "../firebase";
import { AuthContext } from "../Auth";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import { useForm } from "react-hook-form";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Resizer from "react-image-file-resizer";

const ImgTag = styled("img")({
  width: "300px",
  height: "300px",
});

const WatermarkImgTag = styled("img")({
  width: "auto",
  height: "200px",
});

const AHref = styled("a")({
  backgroundColor: "#393652",
  color: "#fff",
  padding: "15px",
  borderRadius: "5px",
  textDecoration: "none",
});

const LabelTag = styled("label")({
  backgroundColor: "#393652",
  color: "#fff",
  padding: "15px",
  borderRadius: "5px",
  cursor: "pointer",
});

export default function () {
  const { currentUser } = useContext(AuthContext);
  const [snapshot, setSnapshot] = useState([]);
  const [watermarkPic, setWatermarkPic] = useState(null);
  const [watermarkImg, setWatermarkImg] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    getValues,
    formState: { errors },
  } = useForm();
  const storage = getStorage(app);

  //   Global Firebase info
  const db = getFirestore(app);

  useEffect(async () => {
    const db = getFirestore(app);
    const querySnapshot = await getDocs(collection(db, "publicationReview"));
    console.log(querySnapshot);
    let arr = [];
    querySnapshot.forEach((doc) => arr.push([doc.data(), doc.id]));
    setSnapshot(arr);
  }, []);

  const handlePublish = async (file) => {
    // Upload watermark pic to storage and get URL
    const storageRefWatermark = ref(
      storage,
      "user_content/" + file[0].uid + "/watermarkPic/" + watermarkPic.name
    );
    await uploadBytes(storageRefWatermark, watermarkPic);

    const watermarkPicUrl = await getDownloadURL(
      ref(
        storage,
        "user_content/" + file[0].uid + "/watermarkPic/" + watermarkPic.name
      )
    );
    const xhr = new XMLHttpRequest();
    xhr.responseType = "blob";
    xhr.open("GET", watermarkPicUrl);
    xhr.send();

    // Upload to publication collection of owner in DB and publicationCollection
    const userPubCollection = collection(
      db,
      "user",
      file[0].uid,
      "publication"
    );
    await addDoc(userPubCollection, {
      ...file[0],
      watermarkPic: watermarkPicUrl,
    });

    await setDoc(doc(db, "publications", file[0].userDocRefID), {
      ...file[0],
      watermarkPic: watermarkPicUrl,
      favorite: 0,
      date: null,
    });

    // Remove from publicationReview and user unprocessed collection in DB
    await deleteDoc(
      doc(
        db,
        "user",
        file[0].uid,
        "unprocessedPublication",
        file[0].userDocRefID
      )
    );

    await deleteDoc(
      doc(db, "publicationReview", file[0].publicationReviewRefID)
    );

    // Filter UI array
    setSnapshot(
      snapshot.filter(
        (snapFile) => snapFile[1] != file[0].publicationReviewRefID
      )
    );
  };

  const handleProcessFile = async (file) => {
    console.log("inside process fie");
    const values = getValues();
    const id = file[1];

    // Resize watermark image
    Resizer.imageFileResizer(
      values[id][0],
      1000,
      1000,
      "JPEG",
      100,
      0,
      (uri) => {
        // resolve(uri);
        console.log("uri", uri);
        setWatermarkPic(uri);
        reader.readAsDataURL(uri);
      },
      "file"
    );

    console.log("values", values[id][0]);
    // setWatermarkPic(values[id][0]);

    const reader = new FileReader();
    reader.onload = (e) => {
      setWatermarkImg({ [id]: e.target.result });
    };
  };

  return (
    <div>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {snapshot.map((file) => (
          <Box
            className="contentRow"
            sx={{
              display: "flex",
              justifyContent: "space-around",
              margin: "10px 0",
              width: "80%",
            }}
          >
            <ImgTag src={file[0].thumbnail} />
            <form
              class="profile_form"
              // onChange={handleSubmit(handleProcessFile)}
              onChange={() => handleSubmit(handleProcessFile(file))}
            >
              <input
                {...register(file[1])}
                id={file[1]}
                type="file"
                name={file[1]}
                class="displayInput"
              />
              <Box
                className="downloadUploadContainer"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <AHref href={file[0].sourceUrl} download>
                  Download Source File
                </AHref>
                <LabelTag
                  for={file[1]}
                  class="profile_changePicture"
                  onClick={() => console.log("clicking label")}
                >
                  Upload
                </LabelTag>
              </Box>
            </form>
            <WatermarkImgTag src={watermarkImg[file[1]]} />
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                sx={{
                  backgroundColor: "#393652",
                  color: "#fff",
                  padding: "15px",
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
                onClick={() => handlePublish(file)}
              >
                Publish
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      {console.log("img", watermarkImg)}
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../Auth";
import NavigationTop from "../GeneralComponents/NavigationTop";
import Box from "@mui/material/Box";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Profile from "./Profile";
import { styled } from "@mui/system";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import app from "../firebase";
import Div100vh from "react-div-100vh";
import Publications from "./Dashboard";
import Footer from "../GeneralComponents/Footer";
import NewPublication from "./NewPublication";
import UploadApplication from "./UploadApplication";

const BottomNavigationContainer = styled(BottomNavigation)({
  borderBottom: "1px solid #d7d7d7",
  margin: "5px 0 20px 0",
});

export default function () {
  const { currentUser } = useContext(AuthContext);
  const [value, setValue] = React.useState(3);
  const [userDBInfo, setUserDBInfo] = useState(null);

  useEffect(async () => {
    const db = getFirestore(app);
    const dbRef = doc(db, "user", currentUser.uid);
    const docSnap = await getDoc(dbRef);
    setUserDBInfo(docSnap.data());
  }, []);

  const RenderProfileBody = () => {
    switch (value) {
      case 0:
        return (
          <Profile userDBInfo={userDBInfo} setUserDBInfo={setUserDBInfo} />
        );
        break;
      case 1:
        return <Box>Downloads</Box>
        break;
      case 2:
        return <UploadApplication />
        break;
      case 3:
        return <Publications userDBInfo={userDBInfo} />
        break;
      case 4:
        return <NewPublication userDBInfo={userDBInfo} />
    }
  };
  return (
    <Box
      className="userHomeContainer"
      sx={{
        backgroundColor: "#f5f5f5",
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <NavigationTop userDBInfo={userDBInfo} />
      <Box
        className="userHome_navi"
        sx={{ width: "100%", height: "50px", margin: "0" }}
      >
        <BottomNavigationContainer
          className="secondaryNavi"
          sx={{
            margin: 0,
            top: "60px",
            width: "100%",
            position: "fixed",
            zIndex: "2",
          }}
          showLabels
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        >
          <BottomNavigationAction label="Profile" />
          <BottomNavigationAction label="Downloads" />
          <BottomNavigationAction label="Apply" />
          <BottomNavigationAction label="Publications" />
          <BottomNavigationAction label="Upload New" />
        </BottomNavigationContainer>
      </Box>
      <Box sx={{ margin: "67px 0 0 0" }}>
        <RenderProfileBody />
      </Box>
      <Footer />
    </Box>
  );
}

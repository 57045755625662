import React, { useState, useContext } from "react";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@material-ui/core";
import { doc, updateDoc, setDoc, getFirestore } from "firebase/firestore";
import app from "../firebase";
import { AuthContext } from "../Auth";
import UploadApplicationReview from "../AdminComponents/UploadApplicationReview";

const useStyles = makeStyles({
  imgUploadContainer1: (props) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#4f4f4f",
    width: "300px",
    height: "300px",
    borderRadius: "5px",
    backgroundSize: "contain",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    backgroundImage: props.backgroundImage1,
  }),

  imgUploadContainer2: (props) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#4f4f4f",
    width: "300px",
    height: "300px",
    borderRadius: "5px",
    backgroundSize: "contain",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    backgroundImage: props.backgroundImage2,
  }),

  imgUploadContainer3: (props) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#4f4f4f",
    width: "300px",
    height: "300px",
    borderRadius: "5px",
    backgroundSize: "contain",
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    backgroundImage: props.backgroundImage3,
  }),
});

const RemoveImgDivContaner = styled("div")({
  width: "300px",
  height: "300px",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "flex-end",
});

const ImgUploadContainer2 = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  backgroundColor: "#4f4f4f",
  width: "300px",
  height: "300px",
  borderRadius: "5px",
});

const ImgUploadContainer3 = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  backgroundColor: "#4f4f4f",
  width: "300px",
  height: "300px",
  borderRadius: "5px",
});

const RemoveImgDiv = styled("div")({
  margin: "0 10px 10px 0",
  backgroundColor: "#393652",
  color: "#eee",
  /* padding: 20px 40px; */
  borderRadius: "15%",
  fontWeight: "700",
  fontSize: "15px",
  width: "36px",
  height: "29px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
});

export default function () {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();
  const [img1, setImg1] = useState(null);
  const [img2, setImg2] = useState(null);
  const [img3, setImg3] = useState(null);
  const [imgArr, setImgArr] = useState([]);
  const [img1Uploaded, setImg1Uploaded] = useState(false);
  const [img2Uploaded, setImg2Uploaded] = useState(false);
  const [img3Uploaded, setImg3Uploaded] = useState(false);
  const [saveAsImg64, setSaveAsImg64] = useState([]);

  //   Global Firebase info
  const { currentUser } = useContext(AuthContext);
  const db = getFirestore(app);

  //   Passing state to CSS to render backgroundImg
  const props = {
    backgroundImage1: "url(" + img1 + ")",
    backgroundImage2: "url(" + img2 + ")",
    backgroundImage3: "url(" + img3 + ")",
  };
  const classes = useStyles(props);
  const [images, setImages] = useState({
    img1: null,
    img2: null,
    img3: null,
  });

  const processImg1Upload = async (data) => {
    console.log("processImg1Upload", data);
    setImg1Uploaded(true);
    setImgArr([...imgArr, data.file1[0]]); // Use for uploading to DB
    setImages({ ...images, img1: data.file1[0] });
    console.log("Img1", data.file1[0]);
    const reader = new FileReader();
    reader.onload = (e) => {
      console.log("filereader", e.target.result);
      setImg1(e.target.result);
      setSaveAsImg64([...saveAsImg64, e.target.result]);
    };
    reader.readAsDataURL(data.file1[0]);
  };

  const processImg2Upload = async (data) => {
    console.log("processImg1Upload", data);
    setImg2Uploaded(true);
    setImgArr([...imgArr, data.file2[0]]); // Use for uploading to DB
    setImages({ ...images, img2: data.file2[0] });
    console.log("Img2", data.file2[0]);
    const reader = new FileReader();
    reader.onload = (e) => {
      console.log("filereader", e.target.result);
      setImg2(e.target.result);
      setSaveAsImg64([...saveAsImg64, e.target.result]);
    };
    reader.readAsDataURL(data.file2[0]);
  };

  const processImg3Upload = async (data) => {
    console.log("processImg3Upload", data);
    setImg3Uploaded(true);
    setImgArr([...imgArr, data.file3[0]]); // Use for uploading to DB
    setImages({ ...images, img3: data.file3[0] });
    console.log("Img3", data.file3[0]);
    const reader = new FileReader();
    reader.onload = (e) => {
      console.log("filereader", e.target.result);
      setImg3(e.target.result);
      setSaveAsImg64([...saveAsImg64, e.target.result]);
    };
    reader.readAsDataURL(data.file3[0]);
  };

  const removeImg = (img) => {
    switch ("" + img + "") {
      case "img1":
        setImg1(null);
        setImg1Uploaded(false);
        break;
      case "img2":
        setImg2(null);
        setImg2Uploaded(false);
        break;
      case "img3":
        setImg3(null);
        setImg3Uploaded(false);
        break;
    }
    setImages({ ...images, [img]: null });
    console.log(images);
  };

  const handleSubmitReview = async () => {
    const dbUserUploadRef = doc(db, "applicationApproval", currentUser.uid);
    await setDoc(dbUserUploadRef, { img: saveAsImg64, uid: currentUser.uid });
  };

  return (
    <Box className="uploadAppContainer" sx={{ width: "100%" }}>
      <Box
        className="upLoadAppContentContainer"
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Box
          className="UploadApp_msgAndLinkContainer"
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <Box
            sx={{
              margin: "80px 0 45px 0",
              maxWidth: "500px",
              textAlign: "center",
            }}
          >
            Hi, your account needs to be authenticated and authorized before you
            can post your content.
          </Box>
          <Box sx={{ margin: "0 0 40px 0" }}>
            <TextField
              //   id="standard-basic"
              key="title"
              label="Link to Portfolio, Twitter, DeviantArt, Pixiv, etc"
              variant="standard"
              focused
              sx={{ width: "100%" }}
              //   value={contentInfo.title}
              //   onChange={(e) =>
              //     setContentInfo({ ...contentInfo, title: e.target.value })
              //   }
            />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            maxWidth: "950px",
            margin: "0 0 25px 0",
            fontSize: "15px",
            fontWeight: "600",
          }}
        >
          Art piece(s) you would like us to show us. &#128522;
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            maxWidth: "950px",
            width: "100%",
          }}
        >
          <Box className={classes.imgUploadContainer1}>
            <form
              class="displayForm"
              onChange={handleSubmit(processImg1Upload)}
            >
              <input
                {...register("file1")}
                id="img1"
                type="file"
                name="file1"
                class="displayInput"
              />
              <label
                for="img1"
                class={
                  img1Uploaded ? "displayNone" : "uploadApp_selectImgLabel"
                }
              >
                Add <br /> JPEG or PNG
              </label>
            </form>
            <RemoveImgDivContaner>
              <RemoveImgDiv
                className={img1Uploaded ? "removeImgDiv" : "displayNone"}
                onClick={() => removeImg("img1")}
              >
                X
              </RemoveImgDiv>
            </RemoveImgDivContaner>
          </Box>
          <Box className={classes.imgUploadContainer2}>
            <form
              class="displayForm"
              onChange={handleSubmit(processImg2Upload)}
            >
              <input
                {...register("file2")}
                id="img2"
                type="file"
                name="file2"
                class="displayInput"
              />
              <label
                for="img2"
                class={
                  img2Uploaded ? "displayNone" : "uploadApp_selectImgLabel"
                }
              >
                Add <br /> JPEG or PNG
              </label>
            </form>
            <RemoveImgDivContaner>
              <RemoveImgDiv
                className={img2Uploaded ? "removeImgDiv" : "displayNone"}
                onClick={() => removeImg("img2")}
              >
                X
              </RemoveImgDiv>
            </RemoveImgDivContaner>
          </Box>
          <Box className={classes.imgUploadContainer3}>
            <form
              class="displayForm"
              onChange={handleSubmit(processImg3Upload)}
            >
              <input
                {...register("file3")}
                id="img3"
                type="file"
                name="file3"
                class="displayInput"
              />
              <label
                for="img3"
                class={
                  img3Uploaded ? "displayNone" : "uploadApp_selectImgLabel"
                }
              >
                Add <br /> JPEG or PNG
              </label>
            </form>
            <RemoveImgDivContaner>
              <RemoveImgDiv
                className={img3Uploaded ? "removeImgDiv" : "displayNone"}
                onClick={() => removeImg("img3")}
              >
                X
              </RemoveImgDiv>
            </RemoveImgDivContaner>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#393652",
            color: "#eee",
            padding: "20px 40px",
            borderRadius: "5px",
            fontWeight: "700",
            fontSize: "17px",
            cursor: "pointer",
            width: "100px",
            textAlign: "center",
            lineHeight: "20px",
            margin: "40px 0 70px",
          }}
          onClick={handleSubmitReview}
        >
          Submit for Review
        </Box>
      </Box>
    </Box>
  );
}

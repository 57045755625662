import React, { useContext, useEffect, useState } from "react";
import {
  doc,
  updateDoc,
  setDoc,
  getFirestore,
  collection,
  getDocs,
} from "firebase/firestore";
import app from "../firebase";
import { AuthContext } from "../Auth";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import Resizer from "react-image-file-resizer";

const ImgTag = styled("img")({
  width: "200px",
  height: "auto",
});

export default function () {
  const { currentUser } = useContext(AuthContext);
  const [img, setImg] = useState([]);

  useEffect(async () => {
    const db = getFirestore(app);
    const querySnapshot = await getDocs(collection(db, "applicationApproval"));
    let arr = [];
    querySnapshot.forEach((doc) => arr.push(doc.data().img));
    setImg(arr);
  }, []);

  return (
    <div>
      {img.map((img) => (
        <Box>
          {img.map((img) => (
            <ImgTag src={img} />
          ))}
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{ color: "#fff", backgroundColor: "green", padding: "15px" }}
            >
              Approve
            </Box>
            <Box
              sx={{ color: "#fff", backgroundColor: "red", padding: "15px" }}
            >
              Reject
            </Box>
          </Box>
        </Box>
      ))}
      {console.log("render", img)}
    </div>
  );
}

import { initializeApp } from "firebase/app";
import "firebase/auth";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAgTXI531SvGzH4hqI4NYtajUZ81AlY_kQ",
  authDomain: "wutok-1c88e.firebaseapp.com",
  projectId: "wutok-1c88e",
  storageBucket: "wutok-1c88e.appspot.com",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);

export default app;

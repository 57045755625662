import React, { useContext, useEffect } from "react";
import { styled } from "@mui/system";
import { AuthContext } from "../Auth";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

const NavContainer = styled("div")({
  backgroundColor: "#393652",
  width: "100%",
  height: "60px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  position: "fixed",
  top: "0",
  zIndex: "2",
});

const SignupContainer = styled("button")({
  width: "80px",
  height: "30px",
  color: "#f7f7f7",
  fontWeight: "400",
  fontSize: "15px",
  background: "none",
  border: "none",
  cursor: "pointer",
  margin: "0 10px 0 0",
});

const MenuContainer = styled("div")({
  height: "60px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "0 15px 0 0",
});

const ProfilePH = styled("img")({
  width: "25px",
  height: "25px",
  borderRadius: "50%",
  margin: "0 10px 0 0",
});

export default function ({ userDBInfo }) {
  const { currentUser } = useContext(AuthContext);
  const auth = getAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    // console.log("naviTOP", userDBInfo);
  }, []);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = async () => {
    await signOut(auth);
    setAnchorEl(null);
    navigate("/");
  };

  const navigate = useNavigate();

  const handleProfileClick = () => {
    navigate("/userhome");
  };

  const goHome = () => {
    navigate("/");
  };

  const handleLogIn = () => {
    navigate("/login", {
      state: { formValue: "login" },
    });
  };

  const handleSignUp = () => {
    navigate("/login", {
      state: { formValue: "signup" },
    });
  };

  return (
    <NavContainer>
      <Box
        className="naviTop_logoContainer"
        sx={{
          display: "flex",
          height: "60px",
          width: "220px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={goHome}
      >
        <Box
          sx={{
            color: "#f7f7f7",
            fontFamily: "Noto serif",
            fontWeight: "600",
            fontSize: "25px",
          }}
        >
          Pixels
        </Box>
        <Box
          sx={{
            fontSize: "17px",
            color: "#f7f7f7",
            fontFamily: "Noto serif",
            fontWeight: "600",
            margin: "0 0 8px 5px",
          }}
        >
          2 beta
        </Box>
      </Box>
      {!!currentUser ? (
        <MenuContainer>
          <Box
            className="sellArt_text"
            sx={{
              color: "#f7f7f7",
              fontWeight: "400",
              fontSize: "15px",
              margin: "0 18px 0 0",
            }}
          >
            {" "}
            Sell your content
          </Box>
          <Box
            className="Cart"
            sx={{
              color: "#f7f7f7",
              fontWeight: "400",
              margin: "0 6px 0 0",
              display: "flex",
            }}
          >
            <ShoppingCartOutlinedIcon sx={{ fontSize: "22px" }} />
          </Box>
          <div>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              {/* <ProfilePH></ProfilePH> */}
              {userDBInfo && <ProfilePH src={userDBInfo.profile_pic_addr} />}
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>Downloads</MenuItem>
              <MenuItem onClick={handleLogOut}>Logout</MenuItem>
            </Menu>
          </div>
        </MenuContainer>
      ) : (
        <MenuContainer>
          <SignupContainer onClick={handleLogIn}> Log in</SignupContainer>
          <SignupContainer onClick={handleSignUp}>Sign up</SignupContainer>
        </MenuContainer>
      )}
    </NavContainer>
  );
}
